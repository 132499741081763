import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type AddBnpCategorySplitInput = {
  applicability: Array<Scalars['String']>;
  category: Scalars['String'];
  name: Scalars['String'];
};

export type ApplicabilityInput = {
  applicability: Scalars['String'];
  modelId: Scalars['String'];
};

export type ArchiveCommonLanguageSpecInput = {
  id: Scalars['ID'];
  revId: Scalars['ID'];
};

export type ArchiveDisclaimerInput = {
  id: Scalars['String'];
  revId: Scalars['String'];
};

export type BnpStatusInput = {
  status: Scalars['Int'];
};

export type BodyJson = {
  __typename?: 'BodyJSON';
  body: Scalars['JSON'];
};

export enum Brand {
  Lexus = 'lexus',
  Tdpr = 'tdpr',
  Toyota = 'toyota'
}

export type CommonLanguageVehicleInput = {
  modelYear: Scalars['String'];
  seriesId: Scalars['ID'];
  seriesName: Scalars['String'];
};

export type CreateCfExtColorInput = {
  code: Scalars['String'];
  colorFamilyIds: Scalars['JSON'];
  hexCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateCfIntColorInput = {
  code: Scalars['String'];
  colorFamilyIds: Scalars['JSON'];
  materialId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateColorFamilyInput = {
  hexCode: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCommonLanguageSpecInput = {
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  isInProgress: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  subCategory: Scalars['String'];
  vehicles?: Maybe<Array<CommonLanguageVehicleInput>>;
};

export type CreateDphCategoryInput = {
  dph: Scalars['Int'];
  name: Scalars['String'];
  nextDph?: Maybe<Scalars['String']>;
  nextDphLiveDate?: Maybe<Scalars['String']>;
  series: Array<Scalars['String']>;
};

export type CreateExtColorLexusInput = {
  code: Scalars['String'];
  colorApplicability?: Maybe<Array<ExtColorApplicabilityLexus>>;
  hexCode?: Maybe<Scalars['String']>;
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
};

export type CreateExtColorToyotaInput = {
  code: Scalars['String'];
  colorApplicability?: Maybe<Array<ExtColorApplicabilityToyota>>;
  hexCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
};

export type CreateGroupTypesLexusInput = {
  models: Scalars['JSON'];
  name: Scalars['String'];
};

export type CreateIntColorLexusInput = {
  armRest: Scalars['String'];
  carpet: Scalars['String'];
  groupApplicability: Scalars['JSON'];
  headliner: Scalars['String'];
  interiorType_subId?: Maybe<Scalars['String']>;
  interiorTypeId: Scalars['String'];
  ip: Scalars['String'];
  isExtraCost?: Maybe<Scalars['String']>;
  materialId: Scalars['String'];
  ornamentType1_subId?: Maybe<Scalars['String']>;
  ornamentType1Id: Scalars['String'];
  ornamentType2_subId?: Maybe<Scalars['String']>;
  ornamentType2Id?: Maybe<Scalars['String']>;
  seatAccent: Scalars['String'];
};

export type CreateIntColorToyotaInput = {
  code: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isExtraCost?: Maybe<Scalars['String']>;
  modelApplicability: Scalars['JSON'];
  name: Scalars['String'];
};

export type CreateIntColorTypesLexusInput = {
  intColorTypes: Array<Scalars['String']>;
  intType: InteriorTypesLexus;
};

export type CreateMaterialInput = {
  materials: Array<Scalars['String']>;
};

export type CreateReportInput = {
  modelYear?: Maybe<Scalars['String']>;
  seriesId?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type CreateSelectionsInput = {
  objectId: Scalars['String'];
  seriesSelections: Array<Scalars['String']>;
};

export type CreateSeriesCategoryInput = {
  name: Scalars['String'];
  nameES: Scalars['String'];
  seriesId: Scalars['String'];
};

export type CreateSeriesInput = {
  group: Scalars['String'];
  name: Scalars['String'];
  sourceBrand?: Maybe<Scalars['String']>;
  sourceSeriesId?: Maybe<Scalars['String']>;
};

export type CreateVehicleModelGradeInput = {
  grades: Scalars['JSON'];
  id?: Maybe<Scalars['ID']>;
};

export type CreateVehicleModelInput = {
  bed?: Maybe<Scalars['String']>;
  cab?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  drive: Scalars['String'];
  engine: Scalars['String'];
  fuelType: Scalars['String'];
  goLiveDate?: Maybe<Scalars['String']>;
  grade: Scalars['String'];
  horsepower: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  isHybrid: Scalars['Boolean'];
  isNotPublishable: Scalars['Boolean'];
  isTDPR?: Maybe<Scalars['Boolean']>;
  isUSVI?: Maybe<Scalars['Boolean']>;
  katashiki?: Maybe<Scalars['String']>;
  seating?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  sourceId?: Maybe<Scalars['String']>;
  tdprCode?: Maybe<Scalars['String']>;
  transmission: Scalars['String'];
  trimTitle: Scalars['String'];
};

export type CreateVehicleModelLexusInput = {
  alternativeOffersDescription?: Maybe<Scalars['String']>;
  cbuNap?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  drive: Scalars['String'];
  engine: Scalars['String'];
  fuelType: Scalars['String'];
  grade: Scalars['String'];
  horsepower: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  isHybrid: Scalars['Boolean'];
  isNotPublishable: Scalars['Boolean'];
  katashiki?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  packageTrim?: Maybe<Scalars['String']>;
  requiredPackageCode?: Maybe<Scalars['String']>;
  seriesSettingId: Scalars['String'];
  sortOrder: Scalars['Int'];
  specialEdition: Scalars['Boolean'];
};

export type CreateVehicleModelSeriesInput = {
  convertible?: Maybe<Scalars['Boolean']>;
  estimatedMileage?: Maybe<Scalars['String']>;
  fuelTypes: Scalars['JSON'];
  horsepower?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPublishable?: Maybe<Scalars['Boolean']>;
  mpge?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  parentRevId?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['String']>;
  seating?: Maybe<Scalars['String']>;
  seriesCategories: Scalars['JSON'];
  startingMSRP?: Maybe<Scalars['String']>;
};

export type CreateVehicleOptionCategoriesInput = {
  categories: Scalars['JSON'];
  id?: Maybe<Scalars['ID']>;
};

export type CreateVehicleOptionInput = {
  categoryId: Scalars['ID'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  modelApplicability?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
};

export type CreateVehicleOptionLexusInput = {
  code: Scalars['String'];
  conflicts?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  optionSpecs?: Maybe<Scalars['JSON']>;
  optionTotalApplicability?: Maybe<Scalars['JSON']>;
  required?: Maybe<Scalars['String']>;
  tooltip?: Maybe<Scalars['Boolean']>;
};

export type CreateVehicleOptionSpecInput = {
  isExclusive: Scalars['Boolean'];
  modelApplicability?: Maybe<Scalars['JSON']>;
  parentId: Scalars['ID'];
  parentRevId: Scalars['String'];
  sms?: Maybe<Scalars['String']>;
  specs: Scalars['String'];
  toms?: Maybe<Scalars['String']>;
};

export type CreateVehiclePackageLexusInput = {
  code: Scalars['String'];
  conflicts?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  packageTotalApplicability?: Maybe<Scalars['JSON']>;
  packageTotalReqApplicability?: Maybe<Scalars['JSON']>;
  required?: Maybe<Scalars['String']>;
};

export type CreateVehiclePackageVarietyLexusInput = {
  code: Scalars['String'];
  conflicts?: Maybe<Scalars['String']>;
  modelApplicability?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  parentId: Scalars['ID'];
  parentRevId: Scalars['String'];
  required?: Maybe<Scalars['String']>;
};

export type DeleteBnpCategorySplitInput = {
  category: Scalars['String'];
  name: Scalars['String'];
  splitId: Scalars['String'];
};

export type DeleteCommonLanguageSpecInput = {
  id: Scalars['ID'];
  revId: Scalars['ID'];
};

export type DeleteDphCategoryInput = {
  categoryId: Scalars['String'];
  revId: Scalars['String'];
};

export type Disclaimer = {
  __typename?: 'Disclaimer';
  description: Scalars['String'];
  description_es?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived?: Maybe<Scalars['Boolean']>;
  lastPublishedDate?: Maybe<Scalars['String']>;
  lastPublishedVersion?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  token: Scalars['String'];
};

export type DisclaimerResponse = {
  __typename?: 'DisclaimerResponse';
  body: Disclaimer;
};

export type ExtColorApplicabilityLexus = {
  groupId: Scalars['String'];
  interiorColorId: Scalars['String'];
};

export type ExtColorApplicabilityToyota = {
  grade: Scalars['String'];
  interiorColorId: Scalars['String'];
};

export type FilterInput = {
  status?: Maybe<Status>;
  version?: Maybe<Scalars['Int']>;
};

export type INode = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export enum InteriorTypesLexus {
  Groups = 'groups',
  InteriorTypes = 'interiorTypes',
  Materials = 'materials',
  Ornaments = 'ornaments'
}


export type KeyFeatureInput = {
  category: Scalars['String'];
  name: Scalars['String'];
  nameES: Scalars['String'];
};

export enum Language {
  En = 'EN',
  Es = 'ES'
}

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  _utilCreateGSTDisclaimers: BodyJson;
  _utilDeleteGSTLioDisclaimer: BodyJson;
  addBnpCategorySplit: BodyJson;
  addSystemSpec: BodyJson;
  archiveCommonLanguageSpec: BodyJson;
  archiveDisclaimer: DisclaimerResponse;
  createCFExtColor: BodyJson;
  createCFIntColor: BodyJson;
  createColorFamily: BodyJson;
  createCommonLanguageCategory: BodyJson;
  createCommonLanguageSpec: BodyJson;
  createDisclaimer: BodyJson;
  createDPHCategory: BodyJson;
  createExtColorLexus: BodyJson;
  createExtColorToyota: BodyJson;
  createGroupTypesLexus: BodyJson;
  createIntColorLexus: BodyJson;
  createIntColorToyota: BodyJson;
  createIntColorTypesLexus: BodyJson;
  createKeyFeature: BodyJson;
  createKeyFeatures: BodyJson;
  createMaterials: BodyJson;
  createReport: BodyJson;
  createSeries: BodyJson;
  createSeriesCategory: BodyJson;
  createSeriesSelections: BodyJson;
  createSignedUrl: BodyJson;
  createSystemSpecs: BodyJson;
  createVehicleModel: BodyJson;
  createVehicleModelGrades: BodyJson;
  createVehicleModelLexus: BodyJson;
  createVehicleModelSeries: BodyJson;
  createVehicleOption: BodyJson;
  createVehicleOptionLexus: BodyJson;
  createVehicleOptionsCategories: BodyJson;
  createVehicleOptionSpecLexus: BodyJson;
  createVehiclePackageLexus: BodyJson;
  createVehiclePackageVarietyLexus: BodyJson;
  deleteBnpCategorySplit: BodyJson;
  deleteCFExtColor: BodyJson;
  deleteCFIntColor: BodyJson;
  deleteColorFamily: BodyJson;
  deleteCommonLanguageCategory: BodyJson;
  deleteCommonLanguageSpec: BodyJson;
  deleteDisclaimer: BodyJson;
  deleteDPHCategory: BodyJson;
  deleteExtColor: BodyJson;
  deleteGroupTypesLexus: BodyJson;
  deleteIntColor: BodyJson;
  deleteSeriesCategory: BodyJson;
  deleteVehicleModel: BodyJson;
  deleteVehicleModelSeries: BodyJson;
  deleteVehicleOption: BodyJson;
  deleteVehicleOptionSpecLexus: BodyJson;
  deleteVehiclePackageVarietyLexus: BodyJson;
  generateDisclaimerReport: BodyJson;
  publishColorFamilyDraft: BodyJson;
  publishCommonLanguageDraft: BodyJson;
  publishDisclaimersToDownstream: BodyJson;
  publishDPH: BodyJson;
  publishDraftDisclaimer: BodyJson;
  publishSeriesCategories: Scalars['Boolean'];
  resetCommonLanguageDraft: BodyJson;
  resetDraftDisclaimers: BodyJson;
  revertBnpChange: BodyJson;
  revertColorChange: BodyJson;
  revertCommonLanguageChange: BodyJson;
  revertDisclaimerChange: BodyJson;
  revertVehicleModelChange: BodyJson;
  revertVehicleOptionChange: BodyJson;
  sendSNSMessage: Scalars['Boolean'];
  sortVehicleModels: BodyJson;
  updateBnpCategoryApplicability: BodyJson;
  updateBnpItemDescription: BodyJson;
  updateBnpItemName: BodyJson;
  updateBnpItemTitleCopy: BodyJson;
  updateBnpReview: BodyJson;
  updateBnpStatus: BodyJson;
  updateCFExtColor: BodyJson;
  updateCFIntColor: BodyJson;
  updateColorFamily: BodyJson;
  updateCommonLanguageCategory: BodyJson;
  updateCommonLanguageSpec: BodyJson;
  updateDisclaimer: BodyJson;
  updateDPHCategory: BodyJson;
  updateExtColorLexus: BodyJson;
  updateExtColorReview: BodyJson;
  updateExtColorStatus: BodyJson;
  updateExtColorStatusAll: BodyJson;
  updateExtColorToyota: BodyJson;
  updateGroupTypesLexus: BodyJson;
  updateIntColorLexus: BodyJson;
  updateIntColorReview: BodyJson;
  updateIntColorStatus: BodyJson;
  updateIntColorToyota: BodyJson;
  updateIntColorTypesLexus: BodyJson;
  updateMaterials: BodyJson;
  updateSeries: BodyJson;
  updateSeriesCategory: BodyJson;
  updateVehicleModel: BodyJson;
  updateVehicleModelGrades: BodyJson;
  updateVehicleModelLexus: BodyJson;
  updateVehicleModelReview: BodyJson;
  updateVehicleModelSeries: BodyJson;
  updateVehicleModelSeriesStatus: BodyJson;
  updateVehicleModelsGoLiveDate: BodyJson;
  updateVehicleModelStatus: BodyJson;
  updateVehicleModelStatusAll: BodyJson;
  updateVehicleOption: BodyJson;
  updateVehicleOptionLexus: BodyJson;
  updateVehicleOptionReview: BodyJson;
  updateVehicleOptionsCategories: BodyJson;
  updateVehicleOptionSpecLexus: BodyJson;
  updateVehicleOptionSpecReviewLexus: BodyJson;
  updateVehicleOptionStatus: BodyJson;
  updateVehicleOptionStatusAll: BodyJson;
  updateVehiclePackageLexus: BodyJson;
  updateVehiclePackageVarietyLexus: BodyJson;
  updateVehiclePackageVarietyReviewLexus: BodyJson;
  uploadDisclaimers: BodyJson;
  userPermissions: BodyJson;
};


export type Mutation_UtilCreateGstDisclaimersArgs = {
  brand: Brand;
  region: Region;
};


export type Mutation_UtilDeleteGstLioDisclaimerArgs = {
  brand: Brand;
  region: Region;
};


export type MutationAddBnpCategorySplitArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: AddBnpCategorySplitInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationAddSystemSpecArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  systemSpecs: Array<Scalars['String']>;
  team: Team;
};


export type MutationArchiveCommonLanguageSpecArgs = {
  brand: Brand;
  payload: ArchiveCommonLanguageSpecInput;
};


export type MutationArchiveDisclaimerArgs = {
  brand: Brand;
  payload: ArchiveDisclaimerInput;
  region: Region;
};


export type MutationCreateCfExtColorArgs = {
  brand: Brand;
  payload: CreateCfExtColorInput;
};


export type MutationCreateCfIntColorArgs = {
  brand: Brand;
  payload: CreateCfIntColorInput;
};


export type MutationCreateColorFamilyArgs = {
  brand: Brand;
  payload: CreateColorFamilyInput;
};


export type MutationCreateCommonLanguageCategoryArgs = {
  brand: Brand;
  categories: Array<Scalars['String']>;
};


export type MutationCreateCommonLanguageSpecArgs = {
  brand: Brand;
  payload: CreateCommonLanguageSpecInput;
};


export type MutationCreateDisclaimerArgs = {
  brand: Brand;
  payload: Scalars['JSON'];
  region: Region;
};


export type MutationCreateDphCategoryArgs = {
  brand: Brand;
  payload: CreateDphCategoryInput;
  region: Region;
};


export type MutationCreateExtColorLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateExtColorLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateExtColorToyotaArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: CreateExtColorToyotaInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateGroupTypesLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateGroupTypesLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateIntColorLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateIntColorLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateIntColorToyotaArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: CreateIntColorToyotaInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateIntColorTypesLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateIntColorTypesLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateKeyFeatureArgs = {
  brand: Brand;
  payload: KeyFeatureInput;
};


export type MutationCreateKeyFeaturesArgs = {
  brand: Brand;
  payload: Array<KeyFeatureInput>;
};


export type MutationCreateMaterialsArgs = {
  brand: Brand;
  payload: CreateMaterialInput;
};


export type MutationCreateReportArgs = {
  brand: Brand;
  reportData: CreateReportInput;
  reportType: Scalars['String'];
};


export type MutationCreateSeriesArgs = {
  brand: Brand;
  payload: CreateSeriesInput;
};


export type MutationCreateSeriesCategoryArgs = {
  brand: Brand;
  payload: CreateSeriesCategoryInput;
};


export type MutationCreateSeriesSelectionsArgs = {
  brand: Brand;
  payload: CreateSelectionsInput;
};


export type MutationCreateSignedUrlArgs = {
  action: S3Action;
  brand: Brand;
  key: Scalars['String'];
  read?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateSystemSpecsArgs = {
  brand: Brand;
};


export type MutationCreateVehicleModelArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: CreateVehicleModelInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleModelGradesArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateVehicleModelGradeInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleModelLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateVehicleModelLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleModelSeriesArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: CreateVehicleModelSeriesInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleOptionArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: CreateVehicleOptionInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleOptionLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateVehicleOptionLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleOptionsCategoriesArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateVehicleOptionCategoriesInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehicleOptionSpecLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  optionTab: Scalars['String'];
  payload: CreateVehicleOptionSpecInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehiclePackageLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateVehiclePackageLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationCreateVehiclePackageVarietyLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: CreateVehiclePackageVarietyLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteBnpCategorySplitArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: DeleteBnpCategorySplitInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteCfExtColorArgs = {
  brand: Brand;
  id: Scalars['ID'];
};


export type MutationDeleteCfIntColorArgs = {
  brand: Brand;
  id: Scalars['ID'];
};


export type MutationDeleteColorFamilyArgs = {
  brand: Brand;
  id: Scalars['ID'];
};


export type MutationDeleteCommonLanguageCategoryArgs = {
  brand: Brand;
  categoryId: Scalars['ID'];
};


export type MutationDeleteCommonLanguageSpecArgs = {
  brand: Brand;
  payload: DeleteCommonLanguageSpecInput;
};


export type MutationDeleteDisclaimerArgs = {
  brand: Brand;
  payload: Scalars['JSON'];
  region: Region;
};


export type MutationDeleteDphCategoryArgs = {
  brand: Brand;
  payload: DeleteDphCategoryInput;
  region: Region;
};


export type MutationDeleteExtColorArgs = {
  brand: Brand;
  id: Scalars['ID'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteGroupTypesLexusArgs = {
  brand: Brand;
  id: Scalars['ID'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteIntColorArgs = {
  brand: Brand;
  id: Scalars['ID'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteSeriesCategoryArgs = {
  brand: Brand;
  seriesCategoryId: Scalars['String'];
};


export type MutationDeleteVehicleModelArgs = {
  brand: Brand;
  id: Scalars['ID'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteVehicleModelSeriesArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  seriesSettingId: Scalars['String'];
  subSeriesSettingId?: Maybe<Scalars['String']>;
  team: Team;
};


export type MutationDeleteVehicleOptionArgs = {
  brand: Brand;
  id: Scalars['ID'];
  language: Language;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationDeleteVehicleOptionSpecLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  optionTab: Scalars['String'];
  parentId: Scalars['ID'];
  seriesId: Scalars['String'];
  specId: Scalars['ID'];
  team: Team;
};


export type MutationDeleteVehiclePackageVarietyLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  parentId: Scalars['ID'];
  seriesId: Scalars['String'];
  specId: Scalars['ID'];
  team: Team;
};


export type MutationGenerateDisclaimerReportArgs = {
  brand: Brand;
  region: Region;
  version: Scalars['String'];
};


export type MutationPublishColorFamilyDraftArgs = {
  brand: Brand;
};


export type MutationPublishCommonLanguageDraftArgs = {
  brand: Brand;
};


export type MutationPublishDisclaimersToDownstreamArgs = {
  brand: Brand;
  lang: Language;
  region: Region;
};


export type MutationPublishDphArgs = {
  brand: Brand;
  region: Region;
};


export type MutationPublishDraftDisclaimerArgs = {
  brand: Brand;
  region: Region;
};


export type MutationPublishSeriesCategoriesArgs = {
  brand: Brand;
};


export type MutationResetCommonLanguageDraftArgs = {
  brand: Brand;
};


export type MutationResetDraftDisclaimersArgs = {
  brand: Brand;
  region: Region;
};


export type MutationRevertBnpChangeArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: RevertBnpChangeInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationRevertColorChangeArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: RevertColorChangeInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationRevertCommonLanguageChangeArgs = {
  brand: Brand;
  payload: RevertCommonLanguageChangeInput;
};


export type MutationRevertDisclaimerChangeArgs = {
  brand: Brand;
  payload: Scalars['JSON'];
  region: Region;
};


export type MutationRevertVehicleModelChangeArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: RevertVehicleModelChangeInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationRevertVehicleOptionChangeArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  payload: RevertVehicleOptionChangeInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationSendSnsMessageArgs = {
  message: Scalars['String'];
  topic: Scalars['String'];
};


export type MutationSortVehicleModelsArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: Array<SortVehicleModelsInput>;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateBnpCategoryApplicabilityArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateBnpCategoryApplicabilityInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateBnpItemDescriptionArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateBnpItemDescriptionInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateBnpItemNameArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateBnpItemNameInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateBnpItemTitleCopyArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateBnpItemTitleCopyInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateBnpReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  payload: UpdateBnpReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateBnpStatusArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateBnpStatusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateCfExtColorArgs = {
  brand: Brand;
  payload: UpdateCfExtColorInput;
};


export type MutationUpdateCfIntColorArgs = {
  brand: Brand;
  payload: UpdateCfIntColorInput;
};


export type MutationUpdateColorFamilyArgs = {
  brand: Brand;
  payload: UpdateColorFamilyInput;
};


export type MutationUpdateCommonLanguageCategoryArgs = {
  brand: Brand;
  payload: UpdateCommonLanguageCategoryInput;
};


export type MutationUpdateCommonLanguageSpecArgs = {
  brand: Brand;
  payload: UpdateCommonLanguageSpecInput;
};


export type MutationUpdateDisclaimerArgs = {
  brand: Brand;
  payload: Scalars['JSON'];
  region: Region;
};


export type MutationUpdateDphCategoryArgs = {
  brand: Brand;
  payload: UpdateDphCategoryInput;
  region: Region;
};


export type MutationUpdateExtColorLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateExtColorLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateExtColorReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  payload: UpdateExtColorReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateExtColorStatusArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateExtColorStatusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateExtColorStatusAllArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  status: Scalars['Int'];
  team: Team;
};


export type MutationUpdateExtColorToyotaArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateExtColorToyotaInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateGroupTypesLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateGroupTypesLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateIntColorLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateIntColorLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateIntColorReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  payload: UpdateIntColorReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateIntColorStatusArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateIntColorStatusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateIntColorToyotaArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateIntColorToyotaInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateIntColorTypesLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateIntColorTypesLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateMaterialsArgs = {
  brand: Brand;
  payload: UpdateMaterialInput;
};


export type MutationUpdateSeriesArgs = {
  brand: Brand;
  payload: UpdateSeriesInput;
};


export type MutationUpdateSeriesCategoryArgs = {
  brand: Brand;
  payload: UpdateSeriesCategoryInput;
};


export type MutationUpdateVehicleModelArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelGradesArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelGradeInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelSeriesArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelSeriesInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelSeriesStatusArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelSeriesStatusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelsGoLiveDateArgs = {
  brand: Brand;
  goLiveDate: Scalars['String'];
  language: Language;
  modelYear: Scalars['Int'];
  payload: Scalars['JSON'];
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelStatusArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleModelStatusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleModelStatusAllArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  status: Scalars['Int'];
  team: Team;
};


export type MutationUpdateVehicleOptionArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleOptionInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleOptionLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  payload: UpdateVehicleOptionReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionsCategoriesArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleOptionCategoriesInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionSpecLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  optionTab: Scalars['String'];
  payload: UpdateVehicleOptionSpecInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionSpecReviewLexusArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  payload: UpdateVehicleOptionSpecReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionStatusArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  payload: UpdateVehicleOptionStatusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehicleOptionStatusAllArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  status: Scalars['Int'];
  team: Team;
};


export type MutationUpdateVehiclePackageLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateVehiclePackageLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehiclePackageVarietyLexusArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  payload: UpdateVehiclePackageVarietyLexusInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUpdateVehiclePackageVarietyReviewLexusArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  payload: UpdateVehiclePackageVarietyReviewInput;
  seriesId: Scalars['String'];
  team: Team;
};


export type MutationUploadDisclaimersArgs = {
  brand: Brand;
  lang: Language;
  payload: Scalars['JSON'];
  region: Region;
  version: Scalars['Int'];
};


export type MutationUserPermissionsArgs = {
  request: UserPermissionsRequest;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  downloadDisclaimers: BodyJson;
  generateCommonLanguageReport: BodyJson;
  getCommonLanguageVersions: BodyJson;
  getDisclaimerCurrentVersion: BodyJson;
  getDisclaimerVersions: BodyJson;
  getFuelTypes: BodyJson;
  getSystemSpecs: BodyJson;
  permissions: BodyJson;
  readAllKeyFeatures: BodyJson;
  readBnpChanges: BodyJson;
  readBnpItems: BodyJson;
  readBnpReview: BodyJson;
  readCFExtColors: BodyJson;
  readCFIntColors: BodyJson;
  readColorChanges: BodyJson;
  readColorFamilies: BodyJson;
  readCommonLanguageCategories: BodyJson;
  readDPHCategories: BodyJson;
  readExtColorReview: BodyJson;
  readExtColors: BodyJson;
  readIntColorReview: BodyJson;
  readIntColors: BodyJson;
  readIntColorTypesLexus: BodyJson;
  readIntColorTypesReviewLexus: BodyJson;
  readKeyFeatures: BodyJson;
  readMaterials: BodyJson;
  readReportLogs: BodyJson;
  readSeries: BodyJson;
  readSeriesCategories: BodyJson;
  readSeriesSelections: BodyJson;
  readVehicleModelChanges: BodyJson;
  readVehicleModelGrades: BodyJson;
  readVehicleModelGradesReviews: BodyJson;
  readVehicleModelMaps: BodyJson;
  readVehicleModelReviews: BodyJson;
  readVehicleModels: BodyJson;
  readVehicleModelSeries: BodyJson;
  readVehicleModelSeriesReviews: BodyJson;
  readVehicleOptionCategoriesReview: BodyJson;
  readVehicleOptionChanges: BodyJson;
  readVehicleOptionReview: BodyJson;
  readVehicleOptions: BodyJson;
  readVehicleOptionsCategories: BodyJson;
  readVehicleOptionSpecLexus: BodyJson;
  readVehiclePackagesVarietyLexus: BodyJson;
  searchCommonLanguageChangeLog: BodyJson;
  searchCommonLanguageSpecs: BodyJson;
  searchDisclaimerChangeLog: BodyJson;
  searchDisclaimers: BodyJson;
  searchDisclaimerTokens: BodyJson;
};


export type QueryDownloadDisclaimersArgs = {
  brand: Brand;
  lang: Language;
  region: Region;
  version: Scalars['String'];
};


export type QueryGenerateCommonLanguageReportArgs = {
  brand: Brand;
  version: Scalars['String'];
};


export type QueryGetCommonLanguageVersionsArgs = {
  brand: Brand;
};


export type QueryGetDisclaimerCurrentVersionArgs = {
  brand: Brand;
  region: Region;
};


export type QueryGetDisclaimerVersionsArgs = {
  brand: Brand;
  region: Region;
};


export type QueryGetFuelTypesArgs = {
  brand: Brand;
};


export type QueryGetSystemSpecsArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryPermissionsArgs = {
  objectId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryReadAllKeyFeaturesArgs = {
  brand: Brand;
};


export type QueryReadBnpChangesArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadBnpItemsArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadBnpReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadCfExtColorsArgs = {
  brand: Brand;
  filter: FilterInput;
};


export type QueryReadCfIntColorsArgs = {
  brand: Brand;
  filter: FilterInput;
};


export type QueryReadColorChangesArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadColorFamiliesArgs = {
  brand: Brand;
  filter: FilterInput;
};


export type QueryReadCommonLanguageCategoriesArgs = {
  brand: Brand;
};


export type QueryReadDphCategoriesArgs = {
  brand: Brand;
  region: Region;
};


export type QueryReadExtColorReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadExtColorsArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadIntColorReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadIntColorsArgs = {
  brand: Brand;
  filter: FilterInput;
  includeAll: Scalars['Boolean'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadIntColorTypesLexusArgs = {
  brand: Brand;
  filter: FilterInput;
  includeAll: Scalars['Boolean'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadIntColorTypesReviewLexusArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadKeyFeaturesArgs = {
  brand: Brand;
  filter: Scalars['String'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadMaterialsArgs = {
  brand: Brand;
};


export type QueryReadReportLogsArgs = {
  brand: Brand;
  reportType: Scalars['String'];
};


export type QueryReadSeriesArgs = {
  brand: Brand;
};


export type QueryReadSeriesCategoriesArgs = {
  brand: Brand;
  getLatestVersion: Scalars['Boolean'];
};


export type QueryReadSeriesSelectionsArgs = {
  brand: Brand;
  objectId: Scalars['String'];
};


export type QueryReadVehicleModelChangesArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleModelGradesArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  includeAll: Scalars['Boolean'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleModelGradesReviewsArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleModelMapsArgs = {
  brand: Brand;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
  versionMap: Scalars['JSON'];
};


export type QueryReadVehicleModelReviewsArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleModelsArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  includeAll: Scalars['Boolean'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleModelSeriesArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  includeAll: Scalars['Boolean'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleModelSeriesReviewsArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleOptionCategoriesReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleOptionChangesArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleOptionReviewArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleOptionsArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleOptionsCategoriesArgs = {
  brand: Brand;
  filter: FilterInput;
  includeAll: Scalars['Boolean'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehicleOptionSpecLexusArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  optionTab?: Maybe<Scalars['String']>;
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadVehiclePackagesVarietyLexusArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QuerySearchCommonLanguageChangeLogArgs = {
  brand: Brand;
  filter: FilterInput;
};


export type QuerySearchCommonLanguageSpecsArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear?: Maybe<Scalars['String']>;
  seriesId?: Maybe<Scalars['String']>;
};


export type QuerySearchDisclaimerChangeLogArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  region: Region;
};


export type QuerySearchDisclaimersArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  region: Region;
};


export type QuerySearchDisclaimerTokensArgs = {
  brand: Brand;
  region: Region;
};

export enum Region {
  Gst = 'GST',
  National = 'NATIONAL',
  Set = 'SET',
  Tdpr = 'TDPR',
  Usvi = 'USVI'
}

export type RevertBnpChangeInput = {
  bnpSplitId?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  changeType: Scalars['String'];
  from?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  to?: Maybe<Scalars['JSON']>;
};

export type RevertColorChangeInput = {
  changeType: Scalars['String'];
  from: Scalars['JSON'];
  id: Scalars['ID'];
  to: Scalars['JSON'];
};

export type RevertCommonLanguageChangeInput = {
  after?: Maybe<Scalars['JSON']>;
  before?: Maybe<Scalars['JSON']>;
  changeType: Scalars['String'];
  id: Scalars['ID'];
  revId: Scalars['ID'];
};

export type RevertVehicleModelChangeInput = {
  changeType: Scalars['String'];
  from?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  revId: Scalars['String'];
  to?: Maybe<Scalars['JSON']>;
};

export type RevertVehicleOptionChangeInput = {
  changeType: Scalars['String'];
  from?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['JSON']>;
};

export enum S3Action {
  Download = 'DOWNLOAD',
  Upload = 'UPLOAD'
}

export type SortVehicleModelsInput = {
  id: Scalars['ID'];
  revId: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export enum Status {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export enum Team {
  AgencyTeam = 'agency_team',
  ProductTeam = 'product_team'
}

export type UpdateBnpCategoryApplicabilityInput = {
  applicability: Array<ApplicabilityInput>;
  category: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateBnpItemDescriptionInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  category: Scalars['String'];
  copy?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  name: Scalars['String'];
  splitId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UpdateBnpItemNameInput = {
  isInProgress: Scalars['Boolean'];
  name: Scalars['String'];
  notes: Scalars['String'];
};

export type UpdateBnpItemTitleCopyInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  category: Scalars['String'];
  copy?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type UpdateBnpReviewInput = {
  category: Scalars['String'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  name: Scalars['String'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateBnpStatusInput = {
  beds: BnpStatusInput;
  cabs: BnpStatusInput;
  drive: BnpStatusInput;
  engine: BnpStatusInput;
  grade: BnpStatusInput;
  seats: BnpStatusInput;
  transmission: BnpStatusInput;
};

export type UpdateCfExtColorInput = {
  code: Scalars['String'];
  colorFamilyIds: Scalars['JSON'];
  hexCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type UpdateCfIntColorInput = {
  code: Scalars['String'];
  colorFamilyIds: Scalars['JSON'];
  id: Scalars['ID'];
  materialId: Scalars['ID'];
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type UpdateColorFamilyInput = {
  hexCode: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type UpdateCommonLanguageCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateCommonLanguageSpecInput = {
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isInProgress: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  revId: Scalars['ID'];
  subCategory: Scalars['String'];
  vehicles?: Maybe<Array<CommonLanguageVehicleInput>>;
};

export type UpdateDphCategoryInput = {
  categoryId: Scalars['String'];
  dph: Scalars['Int'];
  name: Scalars['String'];
  nextDph?: Maybe<Scalars['String']>;
  nextDphLiveDate?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  series: Array<Scalars['String']>;
};

export type UpdateExtColorLexusInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  colorApplicability?: Maybe<Array<ExtColorApplicabilityLexus>>;
  hexCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  unlinkFromTMNA?: Maybe<Scalars['Boolean']>;
};

export type UpdateExtColorReviewInput = {
  changeTypeId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateExtColorStatusInput = {
  id: Scalars['ID'];
  revId: Scalars['String'];
  status: Scalars['Int'];
};

export type UpdateExtColorToyotaInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  colorApplicability?: Maybe<Array<ExtColorApplicabilityToyota>>;
  hexCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  unlinkFromTMNA?: Maybe<Scalars['Boolean']>;
};

export type UpdateGroupTypesLexusInput = {
  id: Scalars['ID'];
  models: Scalars['JSON'];
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type UpdateIntColorLexusInput = {
  armRest: Scalars['String'];
  carpet: Scalars['String'];
  groupApplicability: Scalars['JSON'];
  headliner: Scalars['String'];
  id: Scalars['ID'];
  interiorType_subId?: Maybe<Scalars['String']>;
  interiorTypeId: Scalars['String'];
  ip: Scalars['String'];
  isExtraCost?: Maybe<Scalars['String']>;
  materialId: Scalars['String'];
  ornamentType1_subId?: Maybe<Scalars['String']>;
  ornamentType1Id: Scalars['String'];
  ornamentType2_subId?: Maybe<Scalars['String']>;
  ornamentType2Id?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  seatAccent: Scalars['String'];
};

export type UpdateIntColorReviewInput = {
  changeTypeId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateIntColorStatusInput = {
  id: Scalars['ID'];
  modelApplicability: Scalars['JSON'];
  revId: Scalars['String'];
};

export type UpdateIntColorToyotaInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  id: Scalars['ID'];
  isExtraCost?: Maybe<Scalars['String']>;
  modelApplicability: Scalars['JSON'];
  name: Scalars['String'];
  revId: Scalars['String'];
  unlinkFromTMNA?: Maybe<Scalars['Boolean']>;
};

export type UpdateIntColorTypesLexusInput = {
  id: Scalars['ID'];
  intType: InteriorTypesLexus;
  name: Scalars['String'];
};

export type UpdateMaterialInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateSeriesCategoryInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  nameES: Scalars['String'];
  revId: Scalars['String'];
  seriesId: Scalars['String'];
};

export type UpdateSeriesInput = {
  group: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type UpdateVehicleModelGradeInput = {
  gradeId: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateVehicleModelInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  bed?: Maybe<Scalars['String']>;
  cab?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  drive: Scalars['String'];
  engine: Scalars['String'];
  fuelType: Scalars['String'];
  goLiveDate?: Maybe<Scalars['String']>;
  grade: Scalars['String'];
  horsepower: Scalars['String'];
  id: Scalars['ID'];
  isHybrid: Scalars['Boolean'];
  isNotPublishable: Scalars['Boolean'];
  isTDPR?: Maybe<Scalars['Boolean']>;
  isUSVI?: Maybe<Scalars['Boolean']>;
  katashiki?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  seating?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  sourceId?: Maybe<Scalars['String']>;
  tdprCode?: Maybe<Scalars['String']>;
  transmission: Scalars['String'];
  trimTitle: Scalars['String'];
  unlinkFromTMNA?: Maybe<Scalars['Boolean']>;
};

export type UpdateVehicleModelLexusInput = {
  alternativeOffersDescription?: Maybe<Scalars['String']>;
  cbuNap?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  drive: Scalars['String'];
  engine: Scalars['String'];
  fuelType: Scalars['String'];
  grade: Scalars['String'];
  horsepower: Scalars['String'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isHybrid: Scalars['Boolean'];
  isNotPublishable: Scalars['Boolean'];
  katashiki?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  packageTrim?: Maybe<Scalars['String']>;
  requiredPackageCode?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  seriesSettingId: Scalars['String'];
  sortOrder: Scalars['Int'];
  specialEdition: Scalars['Boolean'];
};

export type UpdateVehicleModelReviewInput = {
  changeTypeId: Scalars['String'];
  id: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateVehicleModelSeriesInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  convertible?: Maybe<Scalars['Boolean']>;
  estimatedMileage?: Maybe<Scalars['String']>;
  fuelTypes: Scalars['JSON'];
  horsepower?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPublishable?: Maybe<Scalars['Boolean']>;
  mpge?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  parentRevId?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  seating?: Maybe<Scalars['String']>;
  seriesCategories: Scalars['JSON'];
  startingMSRP?: Maybe<Scalars['String']>;
  unlinkFromTMNA?: Maybe<Scalars['Boolean']>;
};

export type UpdateVehicleModelSeriesStatusInput = {
  estimatedMileage: Scalars['Int'];
  id: Scalars['String'];
  mpge: Scalars['Int'];
  revId: Scalars['String'];
  seating: Scalars['Int'];
};

export type UpdateVehicleModelStatusInput = {
  bed: Scalars['Int'];
  cab: Scalars['Int'];
  description: Scalars['Int'];
  drive: Scalars['Int'];
  engine: Scalars['Int'];
  grade: Scalars['Int'];
  horsepower: Scalars['Int'];
  id: Scalars['String'];
  revId: Scalars['String'];
  seating: Scalars['Int'];
  transmission: Scalars['Int'];
  trimTitle: Scalars['Int'];
};

export type UpdateVehicleOptionCategoriesInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateVehicleOptionInput = {
  acceptChanges?: Maybe<Scalars['Boolean']>;
  categoryId: Scalars['ID'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  modelApplicability?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  unlinkFromTMNA?: Maybe<Scalars['Boolean']>;
};

export type UpdateVehicleOptionLexusInput = {
  code: Scalars['String'];
  conflicts?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  optionSpecs?: Maybe<Scalars['JSON']>;
  optionTotalApplicability?: Maybe<Scalars['JSON']>;
  required?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  tooltip?: Maybe<Scalars['Boolean']>;
};

export type UpdateVehicleOptionReviewInput = {
  changeTypeId: Scalars['String'];
  id: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateVehicleOptionSpecInput = {
  id: Scalars['ID'];
  isExclusive: Scalars['Boolean'];
  modelApplicability?: Maybe<Scalars['JSON']>;
  parentId: Scalars['ID'];
  parentRevId: Scalars['String'];
  revId: Scalars['String'];
  sms?: Maybe<Scalars['String']>;
  specs: Scalars['String'];
  toms?: Maybe<Scalars['String']>;
};

export type UpdateVehicleOptionSpecReviewInput = {
  changeTypeId: Scalars['String'];
  id: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  parentId: Scalars['String'];
  parentRevId: Scalars['String'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateVehicleOptionStatusInput = {
  id: Scalars['ID'];
  modelApplicability: Scalars['JSON'];
  revId: Scalars['String'];
  status: Scalars['Int'];
};

export type UpdateVehiclePackageLexusInput = {
  code: Scalars['String'];
  conflicts?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isInProgress?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  packageTotalApplicability?: Maybe<Scalars['JSON']>;
  packageTotalReqApplicability?: Maybe<Scalars['JSON']>;
  required?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateVehiclePackageVarietyLexusInput = {
  code: Scalars['String'];
  conflicts?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modelApplicability?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  parentId: Scalars['ID'];
  parentRevId: Scalars['String'];
  required?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UpdateVehiclePackageVarietyReviewInput = {
  changeTypeId: Scalars['String'];
  id: Scalars['ID'];
  isAccepted: Scalars['Boolean'];
  isApplied: Scalars['Boolean'];
  parentId: Scalars['String'];
  parentRevId: Scalars['String'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type UserPermissionsRequest = {
  id: Scalars['String'];
  isGSTAcc: Scalars['Boolean'];
  isLexus: Scalars['Boolean'];
  isSpanish: Scalars['Boolean'];
  isTdpr: Scalars['Boolean'];
  isToyota: Scalars['Boolean'];
};

export type CreateSeriesSelectionsMutationVariables = Exact<{
  brand: Brand;
  payload: CreateSelectionsInput;
}>;


export type CreateSeriesSelectionsMutation = (
  { __typename?: 'Mutation' }
  & { createSeriesSelections: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateSignedUrlMutationVariables = Exact<{
  brand: Brand;
  action: S3Action;
  key: Scalars['String'];
  read?: Maybe<Scalars['Boolean']>;
}>;


export type CreateSignedUrlMutation = (
  { __typename?: 'Mutation' }
  & { createSignedUrl: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type GetFuelTypesQueryVariables = Exact<{
  brand: Brand;
}>;


export type GetFuelTypesQuery = (
  { __typename?: 'Query' }
  & { getFuelTypes: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadSeriesQueryVariables = Exact<{
  brand: Brand;
}>;


export type ReadSeriesQuery = (
  { __typename?: 'Query' }
  & { readSeries: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadSeriesSelectionsQueryVariables = Exact<{
  brand: Brand;
  objectId: Scalars['String'];
}>;


export type ReadSeriesSelectionsQuery = (
  { __typename?: 'Query' }
  & { readSeriesSelections: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadVehicleModelsQueryVariables = Exact<{
  brand: Brand;
  team: Team;
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Language;
  includeAll: Scalars['Boolean'];
  filter?: Maybe<FilterInput>;
}>;


export type ReadVehicleModelsQuery = (
  { __typename?: 'Query' }
  & { readVehicleModels: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);


export const CreateSeriesSelectionsDocument = gql`
    mutation CreateSeriesSelections($brand: Brand!, $payload: CreateSelectionsInput!) {
  createSeriesSelections(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type CreateSeriesSelectionsMutationFn = Apollo.MutationFunction<CreateSeriesSelectionsMutation, CreateSeriesSelectionsMutationVariables>;
export type CreateSeriesSelectionsMutationResult = Apollo.MutationResult<CreateSeriesSelectionsMutation>;
export type CreateSeriesSelectionsMutationOptions = Apollo.BaseMutationOptions<CreateSeriesSelectionsMutation, CreateSeriesSelectionsMutationVariables>;
export const CreateSignedUrlDocument = gql`
    mutation CreateSignedUrl($brand: Brand!, $action: S3Action!, $key: String!, $read: Boolean) {
  createSignedUrl(brand: $brand, action: $action, key: $key, read: $read) {
    body
  }
}
    `;
export type CreateSignedUrlMutationFn = Apollo.MutationFunction<CreateSignedUrlMutation, CreateSignedUrlMutationVariables>;
export type CreateSignedUrlMutationResult = Apollo.MutationResult<CreateSignedUrlMutation>;
export type CreateSignedUrlMutationOptions = Apollo.BaseMutationOptions<CreateSignedUrlMutation, CreateSignedUrlMutationVariables>;
export const GetFuelTypesDocument = gql`
    query GetFuelTypes($brand: Brand!) {
  getFuelTypes(brand: $brand) {
    body
  }
}
    `;
export type GetFuelTypesQueryResult = Apollo.QueryResult<GetFuelTypesQuery, GetFuelTypesQueryVariables>;
export const ReadSeriesDocument = gql`
    query ReadSeries($brand: Brand!) {
  readSeries(brand: $brand) {
    body
  }
}
    `;
export type ReadSeriesQueryResult = Apollo.QueryResult<ReadSeriesQuery, ReadSeriesQueryVariables>;
export const ReadSeriesSelectionsDocument = gql`
    query ReadSeriesSelections($brand: Brand!, $objectId: String!) {
  readSeriesSelections(brand: $brand, objectId: $objectId) {
    body
  }
}
    `;
export type ReadSeriesSelectionsQueryResult = Apollo.QueryResult<ReadSeriesSelectionsQuery, ReadSeriesSelectionsQueryVariables>;
export const ReadVehicleModelsDocument = gql`
    query ReadVehicleModels($brand: Brand!, $team: Team!, $seriesId: String!, $modelYear: Int!, $language: Language!, $includeAll: Boolean!, $filter: FilterInput) {
  readVehicleModels(
    brand: $brand
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    includeAll: $includeAll
    filter: $filter
  ) {
    body
  }
}
    `;
export type ReadVehicleModelsQueryResult = Apollo.QueryResult<ReadVehicleModelsQuery, ReadVehicleModelsQueryVariables>;